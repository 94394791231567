<template>
  <div>
    <b-modal
      v-model="deviationActionModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">{{i18n.deviationAction}}</span>
        </h3>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.selectDeviationAction}}</span>
          </label>
          <div class="col-sm-4 col-form-label">
            <b-form-checkbox v-for="(deviation, idx) in deviationList" :key="idx" v-model="deviationList[idx].checked" size='large' class="overflow-auto">
              <div class="font-weight-normal py-auto my-auto pb-2">{{deviation.connectNm}}- {{deviation.envType}}
                <div style="font-size: 0.5rem;">
                  ({{deviation.startDt}} ~ {{deviation.endDt}}, <i class="fas fa-bell pr-0" style="font-size: 0.7em;"></i>{{deviation.NotiCount}}{{i18n.count}})
                </div>
              </div>
            </b-form-checkbox>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.actionTime}}</span>
          </label>
          <div class="col-sm-4 col-form-label">
            <div class="input-group input-daterange">
            <b-form-datepicker
              v-model="actionDate"
              :locale="locale"
              class="farm-custom-datepicker"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            ></b-form-datepicker>
            <div class="input-group-prepend input-group-append">
              <div class="input-group-text">.</div>
            </div>
            <b-form-timepicker
              v-model="actionTime"
              :locale="locale"
              class="farm-custom-datepicker"
            ></b-form-timepicker>
          </div>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.actionDesc}}</span>
          </label>
          <div class="col-sm-10">
            <textarea
              v-model="actionDesc"
              rows="4"
              wrap="soft"
              class="farm-form-control form-control font-size-h6" >
            </textarea>
          </div>
        </div>
      </div>
      <!-- end::Body -->
      <!--begin::Footer-->
        <div class="card-footer">
          <b-button variant="secondary" size="lg" class="ml-3 float-right" @click="deviationActionModal = false"><span class="">{{i18n.cancel}}</span></b-button>
          <b-button variant="primary" size="lg" class="ml-3 float-right" @click="deviationAction"><span class="">{{i18n.deviationAction}}</span></b-button>
        </div>
      <!--end::Footer-->
      </div>
    </b-modal>
  </div>
</template>

<script>
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT, ACT_SHOW_CHECK_ALERT, ACT_SHOW_ERROR_ALERT} from '@/core/services/store/alert.module'
import {ACT_GET_NOTIFICATION_DETAIL, ACT_INSERT_NOTIFICATION_ACTION} from '@/core/services/variable';

import {getItems, lan, todayToDate, isSuccess} from '@/core/services/funcs'
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "DeviationAction",
  props: {
    connects: Array
  },
  data() {
    return {
      deviationActionModal: false,
      actionDate: '',
      actionTime: '',
      actionDesc: '',
      locale: lan(),
      checkVal: true,
      seq: [],
      deviationList: [],
      i18n: {
        deviationAction: i18n.t('dashboard.deviationAction.deviationAction'),
        selectDeviationAction: i18n.t('dashboard.deviationAction.selectDeviationAction'),
        count: i18n.t('dashboard.deviationAction.count'),
        actionTime: i18n.t('dashboard.deviationAction.actionTime'),
        actionDesc: i18n.t('dashboard.deviationAction.actionDesc'),
        cancel: i18n.t('dashboard.deviationAction.cancel'),
        actionSuccessed: i18n.t('dashboard.deviationAction.actionSuccessed'),
        plzSeclectDeviation: i18n.t('dashboard.deviationAction.plzSeclectDeviation'),
        plzInputDesc: i18n.t('dashboard.deviationAction.plzInputDesc'),
        actionConfirm: i18n.t('dashboard.deviationAction.actionConfirm'),
        actionFinished: i18n.t('dashboard.deviationAction.actionFinished')
      }
    }
  },
  beforeMount() {
    this.getDateTime()
  },
  watch: {
    connects() {
      this.getDeviationList()
    }
  },
  methods: {
    getDeviationList(){
      if(this.$route.params.hashedSeq !== undefined){
        this.deviationActionModal = true
        this.seqs = atob(this.$route.params.hashedSeq).split(',').map(num => Number(num))
        for(let seq of this.seqs){
          this.$store.dispatch(ACT_GET_NOTIFICATION_DETAIL, seq).then(resp => {
            const item = getItems(resp).sort((a,b) => a.seq-b.seq)
            this.deviationList.push({
              seq: seq,
              connectNm: this.connects.find(connect => connect.systemId == item[0].systemId).connectNm,
              envType: item[0].envType,
              startDt: item[0].createDt.slice(0,16),
              endDt: item[item.length - 1].createDt.slice(0,16),
              NotiCount: item.length,
              checked: true
            })
          })
        }
      }
    },
    getDateTime(){
      const today = todayToDate('yyyy/MM/dd hh:mm:00').replaceAll('/', '-').split(' ')
      this.actionDate = today[0]
      this.actionTime = today[1]
    },
    invalidateParams(){
      let message = this.i18n.actionSuccessed
      let result = false
      if(this.deviationList.filter(item => item.checked === true).length === 0) {
        message = this.i18n.plzSeclectDeviation
      }else if(this.actionDesc == null || this.actionDesc == '') {
        message = this.i18n.plzInputDesc
      }else{
        result = true
      }

      if(!result) this.$store.dispatch(ACT_ADD_ALERT, {message: message, color: 'warning'})
      return result
    },
    deviationAction(){
      if(this.invalidateParams()){
        const yesCallback = () => {
          const params = []
          this.deviationList.forEach(item => {
            if(item.checked == true){
              params.push({
                rootAlarmSeq : item.seq,
                managementContent : this.actionDesc,
                alarmStopHour: 0,
                managementDt : this.actionDate.replaceAll('/','') + ' ' + this.actionTime
              })
            }
          })

          this.$store.dispatch(`${ACT_INSERT_NOTIFICATION_ACTION}`, params).then(resp => {
            if(isSuccess(resp)){
              this.$store.dispatch(`${ACT_SHOW_CHECK_ALERT}`, {
                info: {title: '조치가 완료되었습니다.\n(조치내역은 관리자 페이지에서 확인)'},
                icon: 'success'
              })
              this.deviationActionModal = false
            }else{
              this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
              this.deviationActionModal = false
            }
          }).catch(e => {
            console.error(e)
            this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, null)
          })
        }

        this.$store.dispatch(`${ACT_SHOW_CONFIRM_ALERT}`, {
          info: {title: this.i18n.actionConfirm},
          callback: yesCallback
        })
      }
    }
  }
}
</script>
