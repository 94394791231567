<template>
  <!--begin::Farm Map-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-map">
    <!--begin::Header-->
    <div class="card-header border-0">
      <!--begin::card-title-->
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{i18n.diagnosis}}</span>
          <div class="metadata d-flex align-items-center mt-3">
            <span class="text-muted font-weight-bold font-size-sm">
            </span>
          </div>
        </h3>
      </div>
      <!--end::card-title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0">
      <div class="row">
      <div v-show="diagnosisModal==false" :class="`m-auto pb-5 p-5 border rounded text-white font-weight-bold ${envBg}`">
        <div class="text font-size-h5 mt-5 p-3 row">
          <div class="font-size-h5 mt-0 ml-5 col-12"></div>
          <div class="col-12">
          <div v-if="totalPercentageDiagnosis() === 'GOOD'" class="d-flex align-items-center flex-column mb-5">
            <b-icon icon="emoji-smile" class="rounded-circle mb-5" font-scale="10"></b-icon>
            <div>Good</div>
          </div>
          <div v-if="totalPercentageDiagnosis() === 'NOT_BAD'" class="d-flex align-items-center flex-column mb-5">
            <b-icon icon="emoji-neutral" class="rounded-circle mb-5" font-scale="10"></b-icon>
            <div>Not Bad</div>
          </div>
          <div v-if="totalPercentageDiagnosis() === 'BAD'" class="d-flex align-items-center flex-column mb-5">
            <b-icon icon="emoji-frown" class="rounded-circle mb-5" font-scale="10"></b-icon>
            <div>Bad</div>
          </div>
          <div v-if="totalPercentageDiagnosis() === 'NO_DATA'" class="d-flex align-items-center flex-column mb-5">
            <b-icon icon="emoji-frown" class="rounded-circle mb-5" font-scale="10"></b-icon>
            <div>No Data</div>
          </div>
          </div>
        </div>
        <div class="card-body p-3 pt-5">
          <!--begin::Item-->
          <div class="d-flex align-items-center row mb-4">
            <div class="d-flex flex-column col-6 text-hover-primary">
              <div>{{state}}</div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.currentStatus}}
              </span>
            </div>
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{dataCount != null ? Math.floor(dataCount/1440*100): null}}% ({{dataCount}}{{i18n.countDay}})
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.totalData}}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center row mb-4">
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{totalPer}}%
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.totalOutOfRange}}
              </span>
            </div>
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{tempPer}}%
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.tempOutOfRange}}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center row mb-4">
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{humidityPer}}%
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.humOutOfRange}}
              </span>
            </div>
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{co2Per}}%
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.co2OutOfRange}}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center row mb-4">
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{ecPer}}%
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.ecOutOfRange}}
              </span>
            </div>
            <div class="d-flex flex-column col-6">
              <div class="farm-header font-weight-bolder text-white text-hover-primary">
              {{phPer}}%
              </div>
              <span class="farm-text font-size-sm font-weight-bold text-muted">
              {{i18n.phOutOfRange}}
              </span>
            </div>
          </div>
          <!--end::Item-->
        </div>
          <small class="mx-auto ml-3 text font-weight-bold">{{i18n.diagnosisStandards}} : [~ 20% Good], [20 ~ 40% Not Bad], [40% ~ Bad]</small>
        </div>
    </div>
    </div>
    <!--end::Body-->
  </div>
  <!--begin::Farm Map-->
</template>

<script>
import {ACT_GET_MY_FARM, ACT_GET_MY_CONNECTS, ACT_FARM_TOTAL_STATICS} from '@/core/services/variable';
import {lengthCheck, getItem, collectionsCheck} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmDiagnosis",
  beforeMount(){
    this.$store.dispatch(ACT_GET_MY_FARM).then((myFarm)=>{
      if(lengthCheck(myFarm)){
          const myFarmItem = getItem(myFarm)
          this.myFarm = myFarmItem
        }
      this.$store.dispatch(ACT_GET_MY_CONNECTS).then((myConnects)=>{
        if(lengthCheck(myConnects)){
          const myConnectsItem = getItem(myConnects)
          if(collectionsCheck(myConnectsItem.farmConnects, 'seq')) {
            this.connectsCount = myConnectsItem.farmConnects.length
          }
        }

        this.$store.dispatch(ACT_FARM_TOTAL_STATICS, this.myFarm.fno).then(resp =>{
          if(lengthCheck(resp)){
            const statics = getItem(resp)
            this.dataCount = (statics.dataCount/this.connectsCount).toFixed(0)
            this.tempPer = statics.tempPer
            this.humidityPer = statics.humidityPer
            this.co2Per = statics.co2Per
            this.ecPer = statics.ecPer
            this.phPer = statics.phPer
            this.totalPer = statics.totalPer
          }
        })
      })
    })
  },
  props: {
  },
  components: {
  },
  data(){
    return{
      i18n: {
        diagnosis: i18n.t('dashboard.diagnosis'),
        currentStatus:  i18n.t('dashboard.currentStatus'),
        totalData: i18n.t('dashboard.totalData'),
        countDay: i18n.t('dashboard.countDay'),
        totalOutOfRange: i18n.t('dashboard.totalOutOfRange'),
        tempOutOfRange: i18n.t('dashboard.tempOutOfRange'),
        humOutOfRange: i18n.t('dashboard.humOutOfRange'),
        co2OutOfRange: i18n.t('dashboard.co2OutOfRange'),
        ecOutOfRange: i18n.t('dashboard.ecOutOfRange'),
        phOutOfRange: i18n.t('dashboard.phOutOfRange'),
        diagnosisStandards: i18n.t('dashboard.diagnosisStandards'),
      },
      myFarm: {},
      envBg: 'bg-success',
      state: '',
      diagnosisModal: false,

      dataCount: null,
      tempPer: null,
      humidityPer: null,
      co2Per: null,
      ecPer: null,
      phPer: null,
      totalPer: null,
      connectsCount: null,
    }
  },
  methods:{
    totalPercentageDiagnosis() {
      const totalPercentage = this.totalPer
      if(totalPercentage == null) {
        this.envBg = 'bg-warning'
        this.state= 'NO_DATA'
				return 'NO_DATA';
			}else if (totalPercentage <= 20) {
        this.envBg = 'bg-success'
        this.state= 'GOOD'
				return 'GOOD';
			} else if (totalPercentage <= 40) {
        this.envBg = 'bg-success'
        this.state= 'NOT_BAD'
				return 'NOT_BAD';
			} else if(totalPercentage > 40){
        this.envBg = 'bg-warning'
        this.state= 'BAD'
				return 'BAD';
      }
		},
  },
};
</script>
