<template>
  <div class="page-dashboard">
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-12">
        <farm-controller :controller='controller' :connects="connects" :effect-value="connectEffectValue" :fno="fno" :info="info"></farm-controller>
      </div>

      <div class="col-lg-6 col-xxl-3">
        <farm-info :info="info"></farm-info>
      </div>
      <div class="col-lg-6 col-xxl-3">
        <farm-diagnosis></farm-diagnosis>
      </div>
      <div class="col-md-6 col-xxl-3">
        <farm-noti :alarms="alarms" :today-ymd="todayYmd"></farm-noti>
      </div>
      <div class="col-md-6 col-xxl-3">
        <farm-weather :weather="weather" :sido-gb="sidoGb"></farm-weather>
        <farm-price :kamis="kamis"></farm-price>
      </div>

      <div class="col-xxl-4">
        <farm-as :claims="claims"></farm-as>
      </div>
      <div class="col-md-6 col-xxl-4">
        <farm-image :files="files"></farm-image>
      </div>
      <div class="col-md-6 col-xxl-4">
        <farm-notice :notices="notices"></farm-notice>
      </div>
    </div>
    <!--end::Dashboard-->
    <deviation-action :connects="connects"></deviation-action>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import FarmInfo from "@/view/content/dashboard/FarmInfo.vue";
import FarmDiagnosis from "@/view/content/dashboard/FarmDiagnosis.vue";
import FarmNoti from "@/view/content/dashboard/FarmNoti.vue";
import FarmWeather from "@/view/content/dashboard/FarmWeather.vue";
import FarmPrice from "@/view/content/dashboard/FarmPrice.vue";
import FarmController from "@/view/content/dashboard/FarmController.vue";
import FarmAs from "@/view/content/dashboard/FarmAs.vue";
import FarmImage from "@/view/content/dashboard/FarmImage.vue";
import FarmNotice from "@/view/content/dashboard/FarmNotice.vue";
import DeviationAction from "@/view/content/dashboard/DeviationAction.vue";
import {
  ACT_GET_MY_FARM,
  ACT_GET_WEATHER,
  ACT_GET_KAMIS_DAY,
  ACT_GET_MY_FARM_CLAIMS,
  ACT_GET_MY_FARM_NOTICES,
  ACT_GET_FARM_ALARMS,
  ACT_GET_KAMIS_ITEMS, ACT_GET_CROP_PRICES,
} from '@/core/services/variable';
import {getItem, getItems, lengthCheck, syncObj2, todayToDate, getValueWithUnit} from '@/core/services/funcs';

import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmDashboard",
  components: {
    FarmInfo,
    FarmDiagnosis,
    FarmNoti,
    FarmWeather,
    FarmPrice,
    FarmController,
    FarmAs,
    FarmImage,
    FarmNotice,
    DeviationAction
  },
  beforeMount() {
    this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
      if(lengthCheck(resp)){
        const item = getItem(resp);
        syncObj2(this.info, item);
        syncObj2(this.map, item);
        this.mapEffectValue++;
        this.fno = item.fno;
        this.sidoGbDcd = item.sidoGbDcd;
        this.sidoGb = item.sidoGb;
        this.getWeather();

        // files
        this.files = item.farmFiles;
        // connect
        if(item.farmConnects && item.farmConnects.length) {
          this.connects = item.farmConnects;
          this.connectEffectValue++;
        }

        Promise.all([
          this.$store.dispatch(ACT_GET_MY_FARM_CLAIMS),
          this.$store.dispatch(ACT_GET_MY_FARM_NOTICES)
        ])
        .then(([claimResp, noticeResp]) => {
          if(lengthCheck(claimResp)) this.claims = getItems(claimResp)
          if(lengthCheck(noticeResp)) this.notices = getItems(noticeResp)
        })

        this.todayYmd = todayToDate('yyyyMMdd')
        this.$store.dispatch(ACT_GET_FARM_ALARMS, {fno: this.fno, createYmd: this.todayYmd, topCount: 7}).then(resp => {
          if(lengthCheck(resp)) this.alarms = getItems(resp);
        })
      }
    });

    Promise.all([
      this.$store.dispatch(ACT_GET_CROP_PRICES, {useYn : 'Y'}),
      this.$store.dispatch(ACT_GET_KAMIS_ITEMS, {useYn : 'Y'}),
      this.$store.dispatch(ACT_GET_KAMIS_DAY)
    ]).then(([priceResp, itemsResp, kamisResp]) => {
      if(lengthCheck(priceResp)){
        const priceItems = getItems(priceResp);
        priceItems.forEach(price => {
          this.kamis.push({
            name: price.cropNm,
            day: getValueWithUnit(price.dayPrice, '', true),
            week: getValueWithUnit(price.weekPrice, '', true),
            year: getValueWithUnit(price.yearPrice, '', true)
          })
        });
      }

      if(lengthCheck(itemsResp) && lengthCheck(kamisResp)){
        const kamisItems = getItems(itemsResp)
        const kamisJson = JSON.parse(getItem(kamisResp).kamisJson)

        if(kamisJson.data && kamisJson.data.item && kamisJson.data.item.length){
          const items = kamisJson.data.item
          items.forEach(kamis => {
            for(let i = 0; i < kamisItems.length; i++){
              if(kamis.item_code === kamisItems[i].itemCode && kamis.kind_code === kamisItems[i].kindCode && kamis.rank_code === '04'){
                this.kamis.push({
                  name: kamisItems[i].itemName + '/' + kamisItems[i].kindName,
                  day: kamis.dpr1,
                  week: kamis.dpr3,
                  year: kamis.dpr6
                });
                break;
              }
            }
          });
        }
      }
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('dashboard.title'), icon: 'flaticon-squares-1'}]);
    window.scrollTo(0,0);
  },
  data() {
    return {
      fno: 0,
      sidoGbDcd: null,
      sidoGb: null,
      // Info
      info: {
        farmNm: '',
        farmCd: '',
        farmAddr: '',
        companyNm: '',
        cultivateType: '',
        farmCultivateArea: '',
        farmOwnerPhoneNo: '',
        farmCultivateCrops: null,
      },
      controller: {
        temperature: i18n.t('dashboard.temperature'),
        waterTemperature: i18n.t('dashboard.waterTemperature'),
        humidity: i18n.t('dashboard.humidity'),
        CO2: i18n.t('dashboard.CO2'),
        EC: i18n.t('dashboard.EC'),
        pH: i18n.t('dashboard.pH'),
      },
      // Map
      map: {
        title:  i18n.t('dashboard.company.location'),
        latitude: '0',
        longitude: '0',
        farmAddr: '',
        farmAddrDetail: ''
      },
      mapEffectValue: 0,
      // Weather
      weather: {
        weatherId : 0,
        feelLikeTemp : 0,
        humidity : 0,
        temp : 0,
        maxTemp : 0,
        minTemp : 0,
        pressure : 0,
        windSpeed : 0,
      },
      weatherInterval: null,
      // kamis
      kamis: [],
      claims: [],
      notices: [],
      alarms: [],
      todayYmd: '',
      // connect
      connects: [],
      connectEffectValue: 0,
      // files
      files: []
    }
  },
  methods: {
    getWeather(){
      this.$store.dispatch(ACT_GET_WEATHER, this.sidoGbDcd).then(weatherResp => {
        if(lengthCheck(weatherResp)){
          syncObj2(this.weather, getItem(weatherResp))
        }
      })
    },
  }
};
</script>
