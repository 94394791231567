<template>
  <!--begin::Farm weather-->
  <div class="card card-custom gutter-b dashboard-farm-weather">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{ sidoGb }}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-0">
      <div class="d-flex flex-column">
        <div class="weather-status d-flex align-items-center justify-content-between">
          <div class="">
            <span class="temperature font-weight-bold">{{ weather.temp.toFixed(1) }}℃</span>
            <div class="temperature-sub font-weight-bold font-size-sm text-muted">
              <span class="d-inline-block pr-2">{{weatherState.maxTemp}}:{{ weather.maxTemp.toFixed(1) }}℃</span>
              <span>{{weatherState.minTemp}}:{{ weather.minTemp.toFixed(1) }}℃</span>
            </div>
          </div>
          <div>
            <span v-if="weatherIcon" class="weather-icon svg-icon svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg :src="weatherIcon"></inline-svg>
            </span>
          </div>
        </div>
        <div class="weather-list d-flex ">
          <template v-for="(item, i) in weatherItems">
            <!--begin::Item-->
            <div class="weather-item align-items-start" v-bind:key="i">
              <!--begin::Title-->
              <div class="item-title font-weight-bold text-muted">
                {{ item.title }}
              </div>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="item-text">
                <span class="font-size-h3 font-weight-bold">{{ item.data }}</span>
                <span class="font-size-xs font-weight-bold">{{ item.legend }}</span>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </div>
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Farm weather-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "FarmWeather",
  props:{
    weather: Object,
    sidoGb: String
  },
  data() {
    return {
      weatherState: {
        humidity: i18n.t('dashboard.weather.humidity'),
        wind: i18n.t('dashboard.weather.wind'),
        atmosphericPressure: i18n.t('dashboard.weather.atmosphericPressure'),
        minTemp: i18n.t('dashboard.weather.minTemp'),
        maxTemp: i18n.t('dashboard.weather.maxTemp'),
    }
    };
  },
  computed: {
    weatherIcon(){
      if(this.weather){
        if(this.weather.weatherId === 800){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Sun.svg'
        }else if(this.weather.weatherId >= 200 && this.weather.weatherId < 300){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Thunder.svg'
        }else if(this.weather.weatherId >= 300 && this.weather.weatherId < 400){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Rain2.svg'
        }else if(this.weather.weatherId >= 500 && this.weather.weatherId < 600){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Rain1.svg'
        }else if(this.weather.weatherId >= 600 && this.weather.weatherId < 700){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Snow.svg'
        }else if(this.weather.weatherId >= 700 && this.weather.weatherId < 800){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Fog.svg'
        }else if(this.weather.weatherId > 800){
          return 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Cloudy.svg'
        }
      }
      return null
    },
    weatherItems(){
      if(this.weather){
       return [
         {
           title: this.weatherState.humidity,
           data: this.weather.humidity,
           legend: "%"
         },
         {
           title: this.weatherState.wind,
           data: this.weather.windSpeed,
           legend: "m/h"
         },
         // {
         //   title: "강수량",
         //   data: "0",
         //   legend: "mm"
         // },
         {
           title: this.weatherState.atmosphericPressure,
           data: this.weather.pressure,
           legend: "hPa"
         }
       ]
      }
      return [];
    }
  },
  methods: {
  }
};
</script>
