<template>
  <!--begin::Farm Notice-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-notice">
    <!--begin::Header-->
    <div class="card-header border-0">
      <!--begin::card-title-->
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{i18n.notice}}</span>
          <div class="metadata d-flex align-items-center mt-3">
          </div>
        </h3>
      </div>
      <!--end::card-title-->
      <!--begin::card-toolbar-->
<!--      <div class="card-toolbar">-->
<!--        <ul class="nav nav-bold nav-pills">-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" data-toggle="tab" href="#kt_tab_pane_7_1">Month</a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" data-toggle="tab" href="#kt_tab_pane_7_2">Week</a>-->
<!--          </li>-->
<!--          <li class="nav-item ">-->
<!--            <a class="nav-link active" data-toggle="tab" href="#kt_tab_pane_7_3">Day</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
      <!--end::card-toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-0">
      <b-table 
        responsive
        :items="noticeItems"
        :fields="fields"
        head-variant="light"
        class="table-vertical-center"
        :empty-text="'No Data'"
        show-empty
      >
        <template #cell(idx)="data">
          <span class="text-muted">{{ data.item.idx }}</span>
        </template>
        <template #cell(fieldA)="data">
          <a href="javascript:;" class="text-dark" @click="moveNotice(data.item.noticeNo)">{{ data.item.fieldA }}</a>
        </template>
        <template #cell(fieldB)="data">
          <span class="text-muted">{{ data.item.fieldB }}</span>
        </template>
      </b-table>
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Farm Notice-->
</template>

<script>

import {timestampToDate} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmNotice",
  props: {
    notices: Array
  },
  data() {
    return {
      fields: [
        { key: 'idx', label: i18n.t('dashboard.notice.numbering') },
        { key: 'fieldA', label: i18n.t('dashboard.notice.title') },
        { key: 'fieldB', label: i18n.t('dashboard.notice.registeredDate') }
      ],
      i18n:{
        notice : i18n.t('dashboard.notice.notice')
      }
    };
  },
  methods: {
    moveNotice(noticeNo){
      this.$router.push('notice/' + noticeNo);
    }

  },
  computed: {
    noticeItems(){
      if(this.notices && this.notices.length){
        const items = [];
        this.notices.forEach((notice, idx) => {
          items.push({
            'idx': (idx + 1),
            'fieldA': notice.title,
            'fieldB': timestampToDate(notice.createDt),
            'noticeNo': notice.noticeNo
          })
        });
       return items;
      }
      return [];
    }
  }
};
</script>
