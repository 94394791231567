<template>
  <!--begin::Farm Noti-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-noti">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="d-block font-weight-bolder text-dark">
          {{i18n.title}}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ todayDate }}
        </span>
      </h3>
      <div class="card-toolbar">
        <span class="symbol symbol-40 symbol-light-dark mr-2 symbol-icon">
          <span class="symbol-label">
            <i class="fas fa-exclamation text-primary"></i>
          </span>
        </span>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-4">
      <div class="timeline timeline-6 mt-3">
        <template v-for="(item, i) in alarmItems">
          <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="i">
            <!--begin::Label-->
            <div
              class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
            >
              {{ item.time }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xl" v-bind:class="item.badge"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div class="font-weight-mormal timeline-content pl-3">
              <span class="d-block font-weight-bolder">{{ item.title }}</span>
              <span class="text-muted">{{ item.desc }}</span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Farm Noti-->
</template>

<script>
import {dateFormat, timestampToDate} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmNoti",
  props: {
    todayYmd: String,
    alarms: Array
  },
  data() {
    return {
      i18n:{
        title: i18n.t('dashboard.cultivationEnvStandardAlert.title'),
        ecConcentrationOver: i18n.t('dashboard.cultivationEnvStandardAlert.ecConcentrationOver'),
        ecConcentrationBelow: i18n.t('dashboard.cultivationEnvStandardAlert.ecConcentrationBelow'),
        phConcentrationOver: i18n.t('dashboard.cultivationEnvStandardAlert.phConcentrationOver'),
        phConcentrationBelow: i18n.t('dashboard.cultivationEnvStandardAlert.phConcentrationBelow'),
        co2Over: i18n.t('dashboard.cultivationEnvStandardAlert.co2Over'),
        co2Below: i18n.t('dashboard.cultivationEnvStandardAlert.co2Below'),
        highTemperature: i18n.t('dashboard.cultivationEnvStandardAlert.highTemperature'),
        lowTemperature: i18n.t('dashboard.cultivationEnvStandardAlert.lowTemperature'),
        highHumidity: i18n.t('dashboard.cultivationEnvStandardAlert.highHumidity'),
        lowHumidity: i18n.t('dashboard.cultivationEnvStandardAlert.lowHumidity'),
        level1: i18n.t('dashboard.cultivationEnvStandardAlert.level1'),
        level2: i18n.t('dashboard.cultivationEnvStandardAlert.level2'),
        level3: i18n.t('dashboard.cultivationEnvStandardAlert.level3'),
      }
    };
  },
  methods: {
    alarmReason(item) {
      let reason = ''
      
      if(item.ecAlarmYn === '1H') reason += this.i18n.level1 + this.i18n.ecConcentrationOver
      if(item.ecAlarmYn === '1L') reason += this.i18n.level1 + this.i18n.ecConcentrationBelow
      if(item.ecAlarmYn === '2H') reason += this.i18n.level2 + this.i18n.ecConcentrationOver
      if(item.ecAlarmYn === '2L') reason += this.i18n.level2 + this.i18n.ecConcentrationBelow
      if(item.ecAlarmYn === '3H') reason += this.i18n.level3 + this.i18n.ecConcentrationOver
      if(item.ecAlarmYn === '3L') reason += this.i18n.level3 + this.i18n.ecConcentrationBelow

      if(item.phAlarmYn === '1H') reason += this.i18n.level1 + this.i18n.phConcentrationOver
      if(item.phAlarmYn === '1L') reason += this.i18n.level1 + this.i18n.phConcentrationBelow
      if(item.phAlarmYn === '2H') reason += this.i18n.level2 + this.i18n.phConcentrationOver
      if(item.phAlarmYn === '2L') reason += this.i18n.level2 + this.i18n.phConcentrationBelow
      if(item.phAlarmYn === '3H') reason += this.i18n.level3 + this.i18n.phConcentrationOver
      if(item.phAlarmYn === '3L') reason += this.i18n.level3 + this.i18n.phConcentrationBelow

      if(item.co2AlarmYn === '1H') reason += this.i18n.level1 + this.i18n.co2Over
      if(item.co2AlarmYn === '1L') reason += this.i18n.level1 + this.i18n.co2Below
      if(item.co2AlarmYn === '2H') reason += this.i18n.level2 + this.i18n.co2Over
      if(item.co2AlarmYn === '2L') reason += this.i18n.level2 + this.i18n.co2Below
      if(item.co2AlarmYn === '3H') reason += this.i18n.level3 + this.i18n.co2Over
      if(item.co2AlarmYn === '3L') reason += this.i18n.level3 + this.i18n.co2Below
    
      if(item.tempAlarmYn === '1H') reason += this.i18n.level1 + this.i18n.highTemperature
      if(item.tempAlarmYn === '1L') reason += this.i18n.level1 + this.i18n.lowTemperature
      if(item.tempAlarmYn === '2H') reason += this.i18n.level2 + this.i18n.highTemperature
      if(item.tempAlarmYn === '2L') reason += this.i18n.level2 + this.i18n.lowTemperature
      if(item.tempAlarmYn === '3H') reason += this.i18n.level3 + this.i18n.highTemperature
      if(item.tempAlarmYn === '3L') reason += this.i18n.level3 + this.i18n.lowTemperature
      
      if(item.humidityAlarmYn === '1H') reason += this.i18n.level1 + this.i18n.highHumidity
      if(item.humidityAlarmYn === '1L') reason += this.i18n.level1 + this.i18n.lowHumidity
      if(item.humidityAlarmYn === '2H') reason += this.i18n.level2 + this.i18n.highHumidity
      if(item.humidityAlarmYn === '2L') reason += this.i18n.level2 + this.i18n.lowHumidity
      if(item.humidityAlarmYn === '3H') reason += this.i18n.level3 + this.i18n.highHumidity
      if(item.humidityAlarmYn === '3L') reason += this.i18n.level3 + this.i18n.lowHumidity

      if(reason) reason = reason.substr(0, reason.length)
      return reason
    }
  },
  computed: {
    alarmItems(){
      if(this.alarms && this.alarms.length){
        const items = [];
        this.alarms.forEach((alarm, idx) => {
          items.push({
            time: timestampToDate(alarm.createDt, 'hh:mm'),
            title: alarm.connectNm,
            desc: this.alarmReason(alarm),
            badge: "fa fa-genderless " + (idx === 0 ? 'text-primary' : '')
          });
        });
        return items;
      }
      return [];
    },
    todayDate(){
      return dateFormat(this.todayYmd, '. ');
    }

  }
};
</script>
