<template>
  <!--begin::Farm Image-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-image">
    <!--begin::Header-->
    <div class="card-header border-0">
      <!--begin::card-title-->
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{i18n.farmFile}}</span>
          <div class="metadata d-flex align-items-center mt-3">
            <span class="text-muted font-weight-normal font-size-sm">
<!--              파일명 :  <strong class="font-weight-bold">08:23 AM</strong>-->
            </span>
          </div>
        </h3>
      </div>
      <!--end::card-title-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-0">
      <div v-if="files && files.length">
        <b-carousel
          id="carousel-1"
          fade
          v-model="slide"
          controls
          indicators
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <template v-for="(item, idx) in files" >
            <b-carousel-slide :key="idx" v-if="item.attachTypeDcd === '1009002'" >
              <template v-slot:img>
                <div class="file-container">
                <span class="icon-file">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_file_doc.svg" />
                </span>
                  <span class="text">문서</span>
                </div>
              </template>
            </b-carousel-slide>
            <b-carousel-slide :key="idx" v-else-if="item.attachTypeDcd === '1009004'">
              <template v-slot:img>
                <div class="file-container">
                <span class="icon-file">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_file_video.svg" />
                </span>
                  <span class="text">동영상</span>
                </div>
              </template>
            </b-carousel-slide>
            <b-carousel-slide :key="idx" v-else-if="item.attachTypeDcd === '1009005'">
              <template v-slot:img>
                <div class="file-container">
                <span class="icon-file">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/tFarmON/svg/icons/icon_file_etc.svg" />
                </span>
                  <span class="text">기타</span>
                </div>
              </template>
            </b-carousel-slide>
            <b-carousel-slide :key="idx" v-else :img-src="item.filePath"></b-carousel-slide>
          </template>

          <!-- Slides with custom text -->
<!--          <b-carousel-slide img-src="https://dummyimage.com/520x377/000/fff"></b-carousel-slide>-->

          <!-- Slides with image only -->
<!--          <b-carousel-slide img-src="https://dummyimage.com/520x377/000/fff"></b-carousel-slide>-->

          <!-- Slides with img slot -->
          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
<!--          <b-carousel-slide>-->
<!--            <template v-slot:img>-->
<!--              <img-->
<!--                class="d-block img-fluid w-100"-->
<!--                width="1024"-->
<!--                height="480"-->
<!--                src="https://dummyimage.com/520x377/000/fff"-->
<!--                alt="image slot"-->
<!--              >-->
<!--            </template>-->
<!--          </b-carousel-slide>-->

        </b-carousel>
      </div>
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Farm Image-->
</template>
<style>
  .carousel-control-prev-icon{
    background-color: #0c0e18;
  }
  .carousel-control-next-icon{
    background-color: #0c0e18;
  }
</style>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "farm-image",
  props: {
    files: Array
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      i18n:{
        farmFile: i18n.t('dashboard.farmFile')
      }
    };
  },
  methods: {
    lastElement(i) {
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
  computed: {
  }
};
</script>
