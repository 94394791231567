<template>
  <!--begin::Farm price-->
  <div class="card card-custom gutter-b dashboard-farm-price">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{i18n.stapleFoodPrice}}</span>
          <div class="metadata d-flex align-items-center mt-3">
            <span class="text-muted font-weight-normal font-size-sm">
              Last Update <span class="text-dark">09:00 AM</span>
            </span>
          </div>
        </h3>
      </div>
      <div class="card-toolbar">
        <a href="javascript:;" class="btn btn-icon btn-sm mr-1" v-bind:class="{'is-disabled' : this.idx === 0}" data-card-tool="toggle" @click="prev">
          <i class="flaticon2-left-arrow text-dark"></i>
        </a>
        <a href="javascript:;" class="btn btn-icon btn-sm" v-bind:class="{'is-disabled' : (this.kamis.length - 1) === this.idx}" data-card-tool="toggle" @click="next">
          <i class="flaticon2-right-arrow text-dark font-size-xs"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-0">
      <div v-if="target" class="price-status d-flex align-items-end justify-content-between">
        <div class="current-price">
          <span class="item-header font-size-h4 text-muted">
            {{ target.name }}
          </span>
          <div class="item-price d-flex align-items-center">
            <span class="price font-weight-bold" v-bind:class="{'text-danger': target.day > target.week, 'text-info': target.day < target.week}">
              {{ target.day }}
            </span>

            <span class="icon ml-3">
              <i v-bind:class="{'ki ki-long-arrow-down ki-long-arrow-up text-danger': target.day > target.week, 'ki ki-long-arrow-down ki-long-arrow-down text-info': target.day < target.week}"
              ></i>
            </span>
          </div>
        </div>
        <div class="old-price">
          <div class="font-weight-bold mb-2">
            <div>
            <span class="text-muted pr-2">{{i18n.yearAgoPrice}}</span>
            </div>
            <span class="text-dark">{{ target.year }}</span>
          </div>
          <div class="font-weight-bold">
            <div>
            <span class="text-muted pr-2">{{i18n.weekAgoPrice}}</span>
            </div>
            <span class="text-dark">{{ target.week }}</span>
          </div>
        </div>
      </div> 
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Farm price-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmPrice",
  props: {
    kamis: Array
  },
  data() {
    return {
      idx: 0,
      i18n:{
        stapleFoodPrice: i18n.t('dashboard.stapleFoodPrice.title'),
        weekAgoPrice: i18n.t('dashboard.stapleFoodPrice.weekAgoPrice'),
        yearAgoPrice: i18n.t('dashboard.stapleFoodPrice.yearAgoPrice'),
      }
    }
  },
  methods: {
    // lastElement(i) {
    //   if (i === this.list.length - 1) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
    prev(){
      if(this.idx > 0){
        this.idx--
      }
    },
    next(){
      if((this.kamis.length || 0) - 1 > this.idx){
        this.idx++
      }
    }
  },
  computed: {
    target() {
      return this.kamis[this.idx]
    }
  }
};
</script>
