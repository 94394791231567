<template>
  <!--begin::Farm Info-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-info">
    <!--begin::Header-->
    <div class="card-header border-0">
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{ info.farmNm }}</span>
          <div v-if="ownerPhoneNo" class="metadata d-flex align-items-center mt-3">
            <span class="svg-icon svg-icon-light-dark mr-1 svg-icon-xxs">
              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Call.svg" />
            </span>
            <span class="text font-weight-bold font-size-sm text-muted">{{ ownerPhoneNo }}</span>
          </div>
        </h3>
      </div>
<!--      <div class="card-toolbar">-->
<!--        <ToolbarInfo></ToolbarInfo>-->
<!--      </div>-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in items">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-4" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 mr-4 symbol-light-dark">
            <span class="symbol-label">
              <span
                class="svg-icon svg-icon-lg"
                v-bind:class="`svg-icon-${item.style}`"
              >
                <!--begin::Svg Icon-->
                <inline-svg :src="item.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column font-weight-bold">
            <a href="#" class="farm-header text-dark text-hover-primary mb-1">
              {{ item.title }}
            </a>
            <span class="farm-text text-muted">
              {{ item.desc }}
            </span>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
<!--    <div class="card-footer border-0 pt-0">-->
<!--      <b-button variant="primary" class="btn-sm">-->
<!--        <i class="fas fa-clipboard font-size-sm"></i>-->
<!--        <span class="text">자료</span>-->
<!--      </b-button>-->
<!--      <b-button variant="primary" class="btn-sm ml-2">-->
<!--        <i class="fas fa-map font-size-sm"></i>-->
<!--        <span class="text">도면</span>-->
<!--      </b-button>-->
<!--      <b-button variant="primary" class="btn-sm ml-2">-->
<!--        <i class="fas fa-play-circle font-size-sm"></i>-->
<!--        <span class="text">영상</span>-->
<!--      </b-button>-->
<!--    </div>-->
    <!--end::Footer-->
  </div>
  <!--end: Farm Info-->
</template>

<script>
import { mapGetters } from "vuex";
// import ToolbarInfo from "@/view/content/dashboard/ToolbarInfo.vue";
import {phoneFormat, getValueWithDefault, getValueWithUnit, collectionsCheck} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmInfo",
  props: {
    info: Object
  },
  components: {
    // ToolbarInfo
  },
  data() {
    return {
      i18n: {
        code: i18n.t('dashboard.company.code'),
        cultivationType: i18n.t('dashboard.company.cultivationType'),
        name: i18n.t('dashboard.company.name'),
        location: i18n.t('dashboard.company.location'),
        cultivationArea: i18n.t('dashboard.company.cultivationArea'),
        cultivationCrop: i18n.t('dashboard.company.cultivationCrop'),
      }
    };
  },
  methods: {
    getCropNms(crops){
      if(collectionsCheck(crops, 'cropNo')){
        const cropItems = crops.map(crop => crop.cropNm);
        const items = new Set(cropItems)
        const cropitem = [...items]
        return cropitem.join(', ');
      }
      return '-'
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ownerPhoneNo(){
      if(this.info && this.info.farmOwnerPhoneNo){
        return phoneFormat(this.info.farmOwnerPhoneNo);
      }
      return null;
    },
    items(){
      if(this.info){
        return [
          {
            title: getValueWithDefault(this.info.farmCd),
            desc: this.i18n.code,
            svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Code/Code.svg",
            style: "primary"
          },
          {
            title: getValueWithDefault(this.info.farmAddr),
            desc: this.i18n.location,
            svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Map/Marker1.svg",
            style: "primary"
          },
          {
            title: this.info.companyNm,
            desc: this.i18n.name,
            svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Building.svg",
            style: "primary"
          },
          {
            title: getValueWithDefault(this.info.cultivateType),
            desc: this.i18n.cultivationType,
            svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Flower1.svg",
            style: "primary"
          },
          {
            title: getValueWithUnit(this.info.farmCultivateArea, '㎡'),
            desc: this.i18n.cultivationArea,
            svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Ruller.svg",
            style: "primary"
          },
          {
            title: this.getCropNms(this.info.farmCultivateCrops),
            desc: this.i18n.cultivationCrop,
            svg: "https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Wood1.svg",
            style: "primary"
          }
        ];
      }
      return [];
    }
  }
};
</script>
