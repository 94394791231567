<template>
  <!--begin::Farm AS-->
  <div class="card card-custom card-stretch gutter-b dashboard-farm-as">
    <!--begin::Header-->
    <div class="card-header border-0">
      <!--begin::card-title-->
      <div class="card-title top">
        <h3 class="card-label">
          <span class="d-block font-weight-bolder text-dark">{{i18n.claimHistory}}</span>
          <div class="metadata d-flex align-items-center mt-3">
            <span class="text-muted font-weight-normal font-size-sm">
              <strong class="font-weight-bold">{{claimCount}}</strong> Log
            </span>
          </div>
        </h3>
      </div>
      <!--end::card-title-->
      <!--begin::card-toolbar-->
<!--      <div class="card-toolbar">-->
<!--        <span class="symbol symbol-40 symbol-light-dark mr-2">-->
<!--          <span class="symbol-label">-->
<!--            <span class="svg-icon svg-icon-xl svg-icon-primary">-->
<!--              <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Tools/Tools.svg" />-->
<!--            </span>-->
<!--          </span>-->
<!--        </span>-->
<!--      </div>-->
      <!--end::card-toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-0">
      <div class="timeline timeline-6 mt-3">
        <template v-for="(item, i) in claimItems">
          <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="i">

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xs" v-bind:class="item.badge"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div class="timeline-content pl-3 d-flex justify-content-between font-weight-normal">
              <span class="title text-truncate">
                <b-badge :variant="item.variant">{{ item.status }}</b-badge>
                <span class="ml-3 farm-cursor-point" @click="moveClaim(item.boardNo)">{{ item.title }}</span>
              </span>
              <span class="date text-muted">{{ item.date }}</span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Farm AS-->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmAs",
  props: {
    claims: Array
  },
  data() {
    return {
      i18n:{
        claimHistory: i18n.t('dashboard.claimHistory')
      }
    };
  },
  methods: {
    timeUnits(timestamp){
      const elapsedSec = timestamp / 1000; // 9004
      const elapsedMin = timestamp / 1000 / 60; // 150.0666...
      const elapsedHour = timestamp / 1000 / 60 / 60;
      const elapsedDay = timestamp / 1000 / 60 / 60 / 24;
      return {
        sec: elapsedSec,
        min: elapsedMin,
        hour: elapsedHour,
        day: elapsedDay
      }
    },
    moveClaim(boardNo){
      this.$router.push('as-view/' + boardNo);
    }
  },
  computed: {
    claimCount(){
      if(this.claims && this.claims.length){
        return this.claims[0].claimCount
      }
      return 0
    },
    claimItems() {
      if(this.claims && this.claims.length){
        const list = [];
        const now = new Date();
        this.claims.forEach(claim => {
          const item = {
            boardNo: claim.boardNo,
            title: (function(){
              const texts = [claim.title];
              if(claim.content) texts.push(claim.content);
              if(claim.claimStDcd === '1016003') texts.push(claim.answerContent);
              return texts.join(' | ');
            })(),
            status: claim.claimSt,
          }

          let variant;
          if(claim.claimStDcd === '1016001'){
            variant = 'danger'
          }else if(claim.claimStDcd === '1016003'){
            variant = 'info'
          }else if(claim.claimStDcd === '1016004'){
            variant = 'success'
          }else{
            variant = 'dark'
          }
          item['badge'] = ('fa fa-circle text-' + variant);
          item['variant'] = variant;
          // item['date'] = "3 hrs ago"
          const elapsedTime = now.getTime() - claim.createDt;
          const timeUnits = this.timeUnits(elapsedTime);
          let targetDate;
          if(timeUnits.day > 1){
            targetDate = parseInt(timeUnits.day) + ' day ago';
          }else if(timeUnits.hour > 1){
            targetDate = parseInt(timeUnits.hour) + ' hour ago';
          }else if(timeUnits.min > 1){
            targetDate = parseInt(timeUnits.min) + ' minute ago';
          }else{
            targetDate = '1 minute ago';
          }
          item['date'] = targetDate;
          list.push(item);
        })
        return list;
      }
      return [];
    }
  }
};
</script>
