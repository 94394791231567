<template>
  <!--begin::Widget 1_2 -->
  <div class="card card-custom card-stretch gutter-b bg-light card-transparent">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <div v-if="info.cultivateType == '인도어팜-직영'" class="ml-auto" style="position: relative; top: 5px; z-inedx: 1;">
        <button @click="renewModal">
          <i v-if="renewalAlarm" class="flaticon2-notification p-2" style="font-size: 1rem;"></i>
          <i v-else class="flaticon-alert-off p-2" style="font-size: 1rem;"></i>
        </button>
      </div>
      <div v-else class="p-3"></div>
      <div class="chart d-flex flex-column align-items-center">
        <div class="chart-header">
          <span class="font-size-h5">{{i18n.title}}</span>
        </div>
        <div class="chart-body d-flex flex-column align-items-center" :class="{ blur: !collectYn}">
          <div class="chart-svg">
            <div class="chart-temperature d-flex pt-4">
              <div class="temp-axis d-flex flex-column justify-content-between ml-4 mr-2">
                <span class="font-size-sm font-weight-bold text-muted">Full</span>
                <span class="font-size-sm font-weight-bold text-muted">Mid</span>
                <span class="font-size-sm font-weight-bold text-muted">Low</span>
              </div>
              <div class="temp">
                <div class="temp-value" v-bind:style="{'height': waterLevelHeight + '%', backgroundColor: (waterLevel == 2 ? '#2DAEA8':'#74DCD7')}"></div>
              </div>
            </div>
          </div>
          <div class="chart-value">
            <small v-if="collectYn" :class="`font-weight-bolder ${waterLevel == -1 ? 'font-size-h4' : 'font-size-h3'} ${waterLevel == 0 ? 'text-danger' : 'text-black'}`">{{ waterLevelValue }}</small>
            <small v-else :class="`${waterLevel == -1 ? 'font-size-h4' : 'font-size-h3'} ${waterLevel == 0 ? 'text-danger' : 'text-black'}`">{{ waterLevelValue }}</small>
          </div>
        </div>
        <div v-if="!collectYn" class="d-flex flex-column align-items-center mt-30 overlay">
          <i class="flaticon-cancel icon-3x mb-3"></i>
          <div>데이터 미수집</div>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 1_2 -->
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';
import {ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';


export default {
  name: 'Widget1_2',
  props: {
    waterLevel: Number,
    renewalAlarm: Boolean,
    info: Object,
    collectYn: Boolean
  },
  data() {
    return {
      i18n: {
        title: i18n.t('dashboard.waterLevel'),
        renewalAlarmOff: i18n.t('dashboard.renewalAlarm.off'),
        renewalAlarmOn: i18n.t('dashboard.renewalAlarm.on')
      },
    }
  },
  computed: {
    waterLevelValue() {
      return this.waterLevel == -1 ? 'No Data' : this.waterLevel == 0 ? 'Low' : this.waterLevel == 1 ? 'Mid' : 'Full'
    },
    waterLevelHeight() {
      let waterLevelHeight = 0
      if(this.waterLevel == -1){
        waterLevelHeight = 0;
      }else {
        waterLevelHeight = this.waterLevel/2 * 100;
      }
      return waterLevelHeight;
    }
  },
  methods: {
    renewModal(){
      const yesCallback = () =>{
        this.$emit('updateRenewalAlarm', !this.renewalAlarm)
      }
      if (this.renewalAlarm == true){
        this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
          info: { title : this.i18n.renewalAlarmOff},
          callback: yesCallback
        })
      } else if (this.renewalAlarm == false) {
        this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
          info: { title :  this.i18n.renewalAlarmOn},
          callback: yesCallback
        })
      }
    }
  }

};
</script>